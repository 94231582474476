import {Link, graphql} from 'gatsby';
import React from 'react';
import './changelog.css';

import Layout from '../components/layout';
import SEO from '../components/seo';

const getChangelog = edges =>
  edges.map(({node}) => {
    const {
      html,
      fields: {slug},
      frontmatter: {title, imageAlt, imageUrl, date},
    } = node;
    return {slug, title, imageAlt, imageUrl, date, html};
  });

const ChangelogPage = ({data: {allMarkdownRemark}}) => {
  const logs = getChangelog(allMarkdownRemark.edges);

  return (
    <Layout>
      <SEO title="Changelog - Fleet Serverless function fastest" />
      <div className="relative mt-12 md:mt-24 px-6 lg:px-12">
        <div className="container">
          <section className="flex flex-col w-full lg:w-4/6 lg:pr-12">
            <p className="text-gray mb-2">CHANGELOG</p>
            <h1 className="text-3xl sm:text-4xl md:text-5xl leading-tight font-semibold">
              New updates and improvements to Fleet
            </h1>
            <p className="text-gray mt-8 leading-relaxed">
              <a href="https://twitter.com/fleetfn" className="text-primary">
                Follow us on Twitter
              </a>{' '}
              to hear about changes first.
            </p>
          </section>
          <div className="grid gap-16 md:gap-32 lg:gap-48 grid-cols-1 w-full mt-16 md:mt-32 lg:mt-42">
            {logs.map(({slug, date, title, imageAlt, imageUrl, html}) => (
              <article
                className="grid md:grid-cols-3 gap-4 w-full"
                key={date}
                id={date}
              >
                <time className="py-12" dateTime={date}>
                  <Link
                    className="text-gray text-normal hover:text-primary"
                    to={slug}
                  >
                    {title}
                  </Link>
                </time>
                <div className="md:col-span-2">
                  {imageUrl && (
                    <div className="w-full rounded-lg bg-gray-light">
                      <img
                        className="img rounded-lg"
                        alt={imageAlt}
                        src={imageUrl}
                      />
                    </div>
                  )}
                  <div
                    className="changelog-main"
                    dangerouslySetInnerHTML={{__html: html}}
                  />
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/changelog/"}}
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            date
            imageAlt
            imageUrl
            title: date(formatString: "YYYY / MMMM / DD")
          }
        }
      }
    }
  }
`;

export default ChangelogPage;
